import React, {useState} from 'react';
import { Form, Input, Button, Tabs, Grid, Checkbox } from '@arco-design/web-react';
import { IconPhone, IconLock, IconUser, IconPublic, IconIdcard } from '@arco-design/web-react/icon';

const { Row, Col } = Grid;
const TabPane = Tabs.TabPane;

function LoginPage() {
  const [form] = Form.useForm();
  const [isChecked, setIsChecked] = useState(false); //这行代码来控制复选框的状态

  const sendVerificationCode = () => {
    window.alert("发送验证码");
  };

  const handleLogin = () => {
    if (!isChecked) {
      window.alert("请勾选同意服务协议及隐私政策");
      return;
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      <Tabs defaultActiveTab="1" stretch>
        <TabPane key="1" title="手机号登录">
          <Form form={form} layout="vertical">
            <Row gutter={20}>
              {/* 添加单位名称输入框 */}
              <Col span={24}>
                <Form.Item label="单位名称" field="companyName">
                  <Input prefix={<IconPublic />} placeholder="请输入单位名称" />
                </Form.Item>
              </Col>
              {/* 添加姓名输入框 */}
              <Col span={24}>
                <Form.Item label="姓名" field="name">
                  <Input prefix={<IconIdcard />} placeholder="请输入姓名" />
                </Form.Item>
              </Col>
              {/* 手机号输入框 */}
              <Col span={24}>
                <Form.Item label="手机号" field="phone">
                  <Input prefix={<IconPhone />} placeholder="请输入手机号" />
                </Form.Item>
              </Col>
              {/* 验证码输入框 */}
              <Col span={24}>
                <Form.Item label="验证码" field="verificationCode">
                  <Input
                    prefix={<IconLock />}
                    suffix={
                      <Button size="large" type="primary" onClick={sendVerificationCode}>
                        发送验证码
                      </Button>
                    }
                    placeholder="验证码"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Checkbox checked={isChecked} onChange={e => setIsChecked(e)}>
                  未注册的手机号将自动注册，勾选即代表您已阅读并同意服务协议及隐私政策
                </Checkbox>
              </Col>
              {/* 登录按钮 */}
              <Col span={24}>
                <Button type="primary" long onClick={handleLogin}>
                  登录
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane key="2" title="账号密码登录">
          {/* 账号密码登录表单 */}
          <Form form={form} layout="vertical">
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item label="账号" field="username">
                  <Input prefix={<IconUser />} placeholder="账号" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="密码" field="password">
                  <Input.Password prefix={<IconLock />} placeholder="密码" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button type="primary" long>
                  登录
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default LoginPage;
