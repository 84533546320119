import React from "react";
import { Image,Link } from "@arco-design/web-react";
import "./home.css"; // 确保导入了CSS文件

/**
 * Home组件 - 根据传入的show属性决定组件的渲染状态
 * @param {Object} props - 组件接收的props
 * @param {boolean} props.show - 用于控制组件内部分内容是否显示的布尔值
 * @returns 返回组件的JSX结构
 */
function Home({ show }) {
    // 根据show的值动态设置home-container的类名，以实现展开或默认状态
    return(
        <div className={`home-container ${show ? 'home-expanded' : 'home-default'}`}>

            {!show && ( // 当show为false时，渲染下面的<div>内容
                <div className="image-container">
                <Link href="http://image.penguinway.space/i/2024/03/29/6605a63e58f99.png" target="blank"><Image preview={false} src="https://image.penguinway.space/i/2024/03/28/6604d740c492b.png" alt="title" /></Link>
                </div>
            )}
        </div>
    )
}
export default Home;
