import React, { useState } from 'react';
import { Button, Modal, Link } from '@arco-design/web-react';
import { IconLink } from '@arco-design/web-react/icon';

function Doc({ text }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [docModalVisible, setDocModalVisible] = useState({});

  const handleDocModalToggle = (index, isVisible) => {
    setDocModalVisible(prev => ({ ...prev, [index]: isVisible }));
  };

  return (
    <>
      <Button size="small" onClick={() => setModalVisible(true)}>引用知识</Button>
      <Modal
        title="引用知识"
        alignCenter
        style={{ width: "80%", overflow: "auto" }}
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        autoFocus={false}
        focusLocked={true}
      >
        {text.map((doc, index) => (
          <div key={doc.name}>
            <Link icon={<IconLink />} href={doc.url} target="_blank">{doc.name}</Link>
            <br />
            {doc.infos.map((info, id) => (
              <React.Fragment key={info.document_id + id}>
                <Button onClick={() => handleDocModalToggle(info.document_id+id, true)}>
                  {"#" + id }
                </Button>
                <Modal
                  title="知识详情"
                  alignCenter
                  style={{ width: "80%", overflow: "auto" }}
                  visible={docModalVisible[info.document_id+id]}
                  onOk={() => handleDocModalToggle(info.document_id+id, false)}
                  onCancel={() => handleDocModalToggle(info.document_id+id, false)}
                  autoFocus={false}
                  focusLocked={true}
                >
                  <p style={{ whiteSpace: 'pre-wrap' }}>{info.text}</p>
                </Modal>
              </React.Fragment>
            ))}
          </div>
        ))}
      </Modal>
    </>
  );
}

export default Doc;
