import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./style.css"
import "./Chat.css"
import App from "./App";
import LoginPage from "./login";
import ProductPage from "./show";
import Test from "./test";


const root = createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <Layout style={{ height: "auto" }}>
        <Header> <Head /> </Header>
        <Layout>
          <Content> <ChatInterface /> </Content>
          <Sider>Sider</Sider>
        </Layout>
      </Layout> */}
      {/* <Test /> */}
      <App />
      {/* <LoginPage /> */}
      {/* <ProductPage /> */}
  </>
);