import React, { useState, useRef, useEffect } from 'react';
import { Input, Button, Typography, Select, Message, Avatar, Space } from '@arco-design/web-react';
import { IconSend, IconUser, IconRobot, IconVoice } from '@arco-design/web-react/icon';
import { XfVoiceDictation } from '@muguilin/xf-voice-dictation';
import './Chat.css'; // 保留你的样式文件，可能需要做一些调整
import Doc from './Doc';

const TextArea = Input.TextArea;

function ChatInterface({ onInputFocusChange }) { 
  const currentChat = "";
  const [messages, setMessages] = useState("");
  const [inputValue, setInputValue] = useState('');
  const [isWaitingForApiResponse, setIsWaitingForApiResponse] = useState(false);
  const messagesEndRef = useRef(null);
  const xfVoiceRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentDocs, setCurrentDocs] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedModel, setSelectedModel] = useState("ChatGLM");
  const [isRecording, setIsRecording] = useState(false);
  const Option = Select.Option;
  const options = ['ChatGLM'];

  useEffect(() => {
    // fetchGreetingMessage();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // 当消息更新时，滚动到底部

  useEffect(() => {
    xfVoiceRef.current = new XfVoiceDictation({
      APPID: 'd1b792b3',
      APISecret: 'YTlhNmE3ZjA1YmQyNDZmYTY0YmE3NmRm',
      APIKey: 'acadd886da2e1771aca443aae3f77b7a',
      onWillStatusChange: (oldStatus, newStatus) => {
        console.log('识别状态：', oldStatus, newStatus);
      },
      onTextChange: (text) => {
        console.log('识别内容：', text);
        setInputValue(text); // 设置输入框内容
      },
      onError: (error) => {
        console.log('错误信息：', error);
        Message.error('语音识别出错');
      },
    });
  }, []);

  const handleInputFocus = () => {
    setIsInputFocused(true);
    onInputFocusChange(true);
  };

  const handleInputChange = (value) => {
    if (!isWaitingForApiResponse){
      setInputValue(value);
    }
  };

  const copyMessage = (message) => {
    navigator.clipboard.writeText(message).then(() => {
      Message.success('消息已复制');
    });
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() && !isWaitingForApiResponse) {
      setIsWaitingForApiResponse(true);
      const userMessage = inputValue;
      setMessages([...messages, { text: userMessage, sender: 'user' }]);
      setInputValue('');
  
      const apiResponse = await fetchApiResponse(userMessage, selectedModel);
      setMessages((prevMessages) => [...prevMessages, { text: apiResponse.reply, sender: 'api', timestamp: apiResponse.timestamp, documents: apiResponse.documents }]);
      setIsWaitingForApiResponse(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleButtonClick = (text) => {
    setInputValue(text);
  };

  const fetchGreetingMessage = async () => {
    try {
      const response = await fetch('/api/hello', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setMessages([{ text: data.message, sender: 'api' }]);
    } catch (error) {
      console.error(error);
      setMessages([{ text: "抱歉，无法获取问候信息。", sender: 'api' }]);
    }
  };
  async function fetchApiResponse(message, model) {
    try {
      const response = await fetch('/api/message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: message, model: model }),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const datas = await response.json();
      let documents = [];
      if (datas.documents) {
        datas.documents.data.document_slices.forEach(doc => {
          documents.push({ name: doc.document.name, url: doc.document.url, infos: doc.slice_info});
        });
      }
      return {
        reply: datas.reply,
        timestamp: datas.timestamp,
        documents: documents // 这里添加了文档信息
      };
    } catch (error) {
      window.alert(error);
      return { reply: "抱歉，无法获取回复。", timestamp: null };
    }
  }

  const toggleRecording = () => {
    if (isRecording) {
      xfVoiceRef.current && xfVoiceRef.current.stop();
    } else {
      xfVoiceRef.current && xfVoiceRef.current.start();
    }
    setIsRecording(!isRecording);
  };
  
  return (
    <div className="chat-interface" style={{height: "92vh"}}> 
  {/* 条件渲染，如果没有消息则显示提示信息 */}
  
  {messages.length === 0 ? (
    <div className="empty-message-container">
      <Typography.Title heading={5} style={{ textAlign: 'center', marginBottom: '70px' }}>
        <strong>一语中的的元器件专家，精准推荐，省时省力!</strong>
      </Typography.Title>
    <div className="button-row">
      <Button type='secondary' onClick={() => handleButtonClick('电容器的本质特性是什么？')} style={{ width: '45%' }}>
        <Typography.Text ellipsis="True" style={{ fontSize: '22px' }}>电容器的本质特性是什么？</Typography.Text>
      </Button>
      <Button type='secondary' onClick={() => handleButtonClick('多层瓷介电容器在电子电路中的主要作用以及对应的典型电路图有哪些？')} style={{ width: '45%' }}>
        <Typography.Text ellipsis="True" style={{ fontSize: '22px' }}>多层瓷介电容器在电子电路中的主要作用以及对应的典型电路图有哪些？</Typography.Text>
      </Button>
    </div>
    <div className="button-row">
      <Button type='secondary' onClick={() => handleButtonClick('射频、微波多层瓷介电容器主要应用场景有哪些，分别如何选型？')} style={{ width: '45%' }}>
        <Typography.Text ellipsis="True" style={{ fontSize: '22px' }}>射频、微波多层瓷介电容器主要应用场景有哪些，分别如何选型？</Typography.Text>
      </Button>
      <Button type='secondary' onClick={() => handleButtonClick('推荐一款固定电容器，25伏，温度特性优于100PPM，高频特性好，告诉我具体型号规格。')} style={{ width: '45%' }}>
        <Typography.Text ellipsis="True" style={{ fontSize: '22px' }}>推荐一款固定电容器，25伏，温度特性优于100PPM，高频特性好，告诉我具体型号规格。</Typography.Text>
      </Button>
    </div>
    <Space size={'medium'}>
      {<Avatar style={{ backgroundColor: '#ff0074' }} size={25}> <IconRobot> </IconRobot> </Avatar>}
      <Typography.Text ><strong>{"你好，我是元智通，你的元器件专家。有疑问?随时问我!"}</strong></Typography.Text>
    </Space>
  </div>
  ) : (
    <div className="message-list">
      {/* 消息列表 */}
      {messages.map((message, index) => (
        <div key={index} style={{position: 'relative', marginBottom: '10px' }} className={`message ${message.sender}`}>
          <Space size={'medium'}>
          {message.sender === "api" ? <Avatar style={{ backgroundColor: '#ff0074' }} size={25}> <IconRobot> </IconRobot> </Avatar>: <Avatar style={{ backgroundColor: '#3370ff' }} size={25}> <IconUser /> </Avatar>}
            <Typography.Text copyable="True"><strong>{message.text}</strong></Typography.Text>
          </Space>
          {message.sender === 'api' && (
            <>
              <Doc text={message.documents} />
            </>
          )}
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  )}
      <div className="input-area">
        <div className="media-button">
          <Select
        placeholder='请选择模型'
        defaultValue='ChatGLM' // 默认值设为ChatGLM
        style={{ width: 120, height: "53px" }}
        onChange={(value) => {
          setSelectedModel(value);
          Message.info({
            content: `已切换到 ${value}.`,
            showIcon: true,
          });
        }}
      >
        {options.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
        </div>
        <TextArea
          className="input"
          autoSize={{ minRows: 2}}
          onFocus={() => handleInputFocus(true)}
          style={{ marginRight: '8px', flex: 1 }}
          placeholder="输入你的问题或需求"
          value={inputValue}
          onChange={(inputValue) => handleInputChange(inputValue)}
          onPressEnter={handleKeyPress}
          disabled={isWaitingForApiResponse} // 在等待API回复时禁用输入
        />
        <Button type="outline"  
            size="small"
            style={
              {
                height: "53px", 
                width: "53px",
                color: isRecording ? 'red' : undefined, // 当录音时变红
                borderColor: isRecording ? 'red' : undefined,
              }}
            icon={<IconVoice />}
            onClick={toggleRecording}
            >
        </Button>
        <Button type="outline"  
            size="small"
            style={{height: "53px"}}
            icon={<IconSend />}
            onClick={handleSendMessage}
            disabled={isWaitingForApiResponse}// 在等待API回复时禁用按钮
            status={isWaitingForApiResponse? 'Secondary' : 'outline'}
            loading={isWaitingForApiResponse} //
            >
              发送
        </Button>
      </div>
    </div>
  );
}

export default ChatInterface;
