import React, { useState } from 'react';
import { Layout, Button, Modal, Input, Message, Tabs, Typography } from '@arco-design/web-react';
import { IconHistory, IconPlus, IconCopy } from '@arco-design/web-react/icon';

const Content = Layout.Content;
const Header = Layout.Header;
const TabPane = Tabs.TabPane;
const TextArea = Input.TextArea;

let count = 5;
const style = {
  textAlign: 'center',
  marginTop: 20,
};
const initTabs = [...new Array(count)].map((x, i) => ({
  title: `Tab ${i + 1}`,
  key: `key${i + 1}`,
  content: `${i + 1}`,
}));

function Test() {
  const [tabs, setTabs] = useState(initTabs);
  const [activeTab, setActiveTab] = useState('key2');
  const [tabMessages, setTabMessages] = useState({ chat1: [] });
  const [inputValue, setInputValue] = useState('');

  const addMessage = () => {
    if (inputValue.trim()) {
      const messages = tabMessages[activeTab] || [];
      setTabMessages({ ...tabMessages, [activeTab]: [...messages, inputValue] });
      setInputValue('');
    }
  };

  const copyMessage = (message) => {
    navigator.clipboard.writeText(message).then(() => {
      Message.success('消息已复制');
    });
  };

  const handleAddTab = () => {
    const newTab = {
      title: `New Tab${++count}`,
      key: `new key${count}`,
      content: `${count}`,
    };
    setTabs([...tabs, newTab]);
    setActiveTab(newTab.key);
  };

  const handleDeleteTab = (key) => {
    const index = tabs.findIndex((x) => x.key === key);
    const newTabs = tabs.slice(0, index).concat(tabs.slice(index + 1));

    if (key === activeTab && index > -1 && newTabs.length) {
      setActiveTab(newTabs[index] ? newTabs[index].key : newTabs[index - 1].key);
    }

    if (index > -1) {
      setTabs(newTabs);
    }
  };

  return (
    <Layout style={{ height: '100vh', display: 'column' }}>
      <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button icon={<IconHistory />} onClick={() => {}}>历史消息</Button>
        <h1>元器件选型专家</h1>
        <Button icon={<IconPlus />} onClick={handleAddTab}>新建对话</Button>
      </Header>
      <Content style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
        <Tabs 
          editable
          type="card"
          activeKey={activeTab}
          onAddTab={handleAddTab}
          onDeleteTab={(key) => handleDeleteTab(key)}
          onChange={setActiveTab}
        >
          {tabs.map((tab) => (
            <TabPane destroyOnHide key={tab.key} title={tab.title}>
              <Typography.Paragraph style={style}>
                {`Content of Tab Panel ${tab.content}`}
              </Typography.Paragraph>
              {(tabMessages[tab.key] || []).map((message, index) => (
                <div key={index} style={{position: 'relative', marginBottom: '10px' }}>
                  <div>{message}</div>
                  <Button
                    size="small"
                    style={{ position: 'absolute', right: 0, bottom: 0 }}
                    icon={<IconCopy />}
                    onClick={() => copyMessage(message)}
                  />
                </div>
              ))}
            </TabPane>
          ))}
        </Tabs>
        <TextArea
          value={inputValue}
          onChange={(e) => setInputValue(e)}
          onPressEnter={addMessage}
          placeholder="输入消息"
          rows={4}
        />
        <Button type="primary" onClick={addMessage} style={{ marginTop: '10px' }}>发送</Button>
      </Content>
    </Layout>
  );
}

export default Test;
