import { useState, createContext, useContext } from 'react';
import { Layout, Menu, Space } from '@arco-design/web-react';
import { IconHome, IconCalendar, IconPlus, IconMessage } from '@arco-design/web-react/icon';
import Head from './Head';
import ChatInterface from "./Chat";
import Home from './home';




const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Sider = Layout.Sider;
const Content = Layout.Content;
const Header = Layout.Header;
const collapsedWidth = 60;
const normalWidth = 150;

function App() {
  const [collapsed, setCollapsed] = useState(true);
  const [siderWidth, setSiderWidth] = useState(normalWidth);
  // 添加一个状态来跟踪当前选中的对话
  const [currentChat, setCurrentChat] = useState('');
  const [inputFocused, setInputFocused] = useState(false);

  const handleInputFocusChange = (focused) => {
    setInputFocused(focused);
  };

  // const onCollapse = (collapsed) => {
  //   setCollapsed(collapsed);
  //   setSiderWidth(collapsed ? collapsedWidth : normalWidth);
  // };

  // const handleSelect = ({ key }) => {
  //   // 根据选择的菜单项设置当前的聊天
  //   setCurrentChat(key);
  //   window.alert(key);
  // };

  return (
    <Layout>
      {/* <Home show={inputFocused} /> */}
      <Layout >
        <Header> <Head /> </Header>
        <Content>
          <ChatInterface onInputFocusChange={handleInputFocusChange} />
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;