import React, {useState} from "react";
import { Typography } from "@arco-design/web-react";
import { Image,Button,Select } from "@arco-design/web-react";
import { IconSettings, IconWechat } from "@arco-design/web-react/icon";
import "@arco-design/web-react/dist/css/arco.css";
import "./style.css"; // 确保导入了CSS文件

const transparentButtonStyle = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  boxShadow: 'none'
};

const Head = () => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="container">
      <Button icon={<IconSettings style={{"font-size":30}}/>} size="large" style={transparentButtonStyle}></Button>
      <div className="title-container">
        
        {/* <Image className="image" src="http://image.penguinway.space/i/2024/03/25/660192417fd29.png" alt="Logo" width={40} /> */}
        <Typography.Title className="title" heading={6}><strong>智能问答</strong></Typography.Title>
        
      </div>
      <Button icon={<IconWechat style={{"font-size":30}}/>} size="large" style={transparentButtonStyle} onClick={() => setVisible(true)}></Button>
      <Image.Preview
        src='http://image.penguinway.space/i/2024/05/12/6640bd8028fc0.jpg'
        visible={visible}
        onVisibleChange={setVisible}
      />
    </div>
  );
}
export default Head;
